import React from 'react';
import {Box, Grid, TextField, Typography, Dialog, List, ListItem, 
    Avatar, ListItemButton} from "@mui/material";
import { loaderAnimation } from '../../Api';
import FaceIcon from '@mui/icons-material/Face';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Colors from "../../components/ui/Colors";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

export default function ConsumePlanChoose(props) {

    const {loginUser} = props;
    const history = useHistory();
    const {t} = useTranslation();
    
    const[loading, setLoading] = React.useState(false);

    const[actionBox, setActionBox] = React.useState(true);

    const openActionBox = () => {
        setActionBox(true);
    };

    const closeActionBox = () => {
        setActionBox(false);
    };

    const goToLlmForm = () => {
        history.push({
            pathname: "/consumer/llm_choose",
            // state: {topicId: topicId}
            // state: {topicId: 49372} //Test env
        });
    };

    const goToManualForm = () => {
        history.push({
            pathname: "/retirement_plan/topics",
            state:{
                username: props.location?.state?.username ? props.location.state.username.username : null
            }
        });
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                    overflowY: "scroll"
                }}
            >
                {loading ? loaderAnimation:
                    <Dialog open={actionBox} onClose={closeActionBox}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: {xs: "80%", sm: "40%", md: "30%"},
                                    padding: "1.5rem", // Add padding to the dialog
                                    borderRadius: "16px", // Softer corners
                                },
                            },
                        }}
                        >
                            <List sx={{width: "100%",}}>
                                <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                    <ListItemButton  onClick={goToLlmForm}>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <Avatar sx={{ backgroundColor: Colors.primary, width: "3rem", height: "3rem" }}>
                                                    <FaceIcon sx={{ fontSize: "1.5rem", color: "white" }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <Typography variant="h6" sx={{ml:{xs: "0.8rem", sm: "1rem"}}}>{t("HandleProfile.grace")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                    <ListItemButton  onClick={goToManualForm}>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <Avatar sx={{ backgroundColor: Colors.primary, width: "3rem", height: "3rem" }}>
                                                    <FormatListNumberedIcon sx={{ fontSize: "1.5remrem", color: "white" }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <Typography variant="h6" sx={{ml:{xs: "0.8rem", sm: "1rem"}}}>{t("ConsumerPlan.manualForm")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Dialog>
                }
            </Box>
        </Box>
    )
}

import React from 'react';
import { Box, Grid } from "@mui/material";
import ClientCreation from './ClientCreation';
import ClientInvite from './ClientInvite';
import ClientPlanForm from './ClientPlanForm';
import { loaderAnimation } from '../../Api';
import { getAllAdvisorClientsInfo } from '../../utilityFunctions/WellnessUtil';
import swal from 'sweetalert';

export default function ClientManagement(props) {
  const {loginUser} = props;
  const [loading, setLoading] = React.useState(true);
  const [usernames, setUsernames] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;
    
    const fetchInitialData = async () => {
      try {
        const response = await getAllAdvisorClientsInfo(loginUser.jwtToken);
        if(!mounted) return;
        
        if(!response) {
          swal("Error", "Failed to load client list", "error");
          return;
        }
        setUsernames(response);
      } catch (error) {
        console.error(error);
        swal("Error", "Failed to load data", "error");
      } finally {
        if(mounted) setLoading(false);
      }
    };

    fetchInitialData();
    return () => { mounted = false; };
  }, [loginUser]);

  return (
    <Box sx={{
        backgroundColor: '#f5f5f5',
        padding: '2rem',
        display: "flex", 
        justifyContent: "center", 
        width: "100%", 
        height: "100%",
        position: "relative"
    }}>
        {loading ? (
            <Box sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)"
            }}>
                {loaderAnimation}
            </Box>
        ) : (
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <ClientPlanForm loginUser={loginUser} usernames={usernames} />
                </Grid>
                <Grid item xs={12} md={7}>
                    <ClientCreation loginUser={loginUser} setLoading={setLoading} />
                </Grid>
                <Grid item xs={12} md={5}>
                    <ClientInvite loginUser={loginUser} setLoading={setLoading} />
                </Grid>
            </Grid>
        )}
    </Box>
  );
} 
import React from 'react';
import { Box, TextField, Typography, Button, Grid } from "@mui/material";
import Colors from "../../components/ui/Colors";
import { inviteClient } from "../../utilityFunctions/WellnessUtil";
import swal from "sweetalert";
import { loaderAnimation } from '../../Api';

export default function ClientInvite(props) {
    const {loginUser, setLoading} = props;
    const [email, setEmail] = React.useState("");

    const handleInvite = async() => {
        try {
            setLoading(true);
            const response = await inviteClient({email: email}, loginUser.jwtToken);

            if(!response){
                console.log("Invitation not sent");
                swal("Invitation not sent", {
                    icon: "error",
                });
                return;
            }
            
            swal("Invitation sent", {
                icon: "success",
            });
        } catch (error) {
            console.error(error);
            swal("Error sending invitation", {
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '2rem',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            height: '100%'
            }}>
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <Box sx={{display: "flex", justifyContent: "start", flexDirection: "column", textAlign: "start"}}>
                        <Typography variant="h6" sx={{ mb: "0.5rem" }}>Invite Client to My Plan Keeper</Typography>
                        <Typography variant="body2" sx={{ mb: "1rem" }}>Easily invite your clients to start their personalized wellness journey. Just enter therir email and we'll send them and invitation</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Client Email"
                        variant="standard"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                </Grid>

                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button 
                        variant="contained"
                        fullWidth
                        onClick={handleInvite}
                        size="big" 
                        sx={{backgroundColor: Colors.secondary, marginTop: "2rem", height: "3rem", width: {xs: "70%", sm: "50%", md: "40%"}, borderRadius: "100px 100px 100px 100px"}}
                    >
                            Send Invitation
                        </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

import React from 'react';
import {Box, Grid, Typography, Button, List, ListItem, ListItemButton, Avatar, Tooltip, Card} from "@mui/material";
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import { useTranslation } from 'react-i18next';
import {getTopicSummary} from '../../../utilityFunctions/WellnessUtil';
import swal from "sweetalert";
import { useHistory } from "react-router-dom";


export default function TopicSummary(props) {

    const summaryPendingText = "Watch your retirement plan unfold here as you chat with Grace™. Click any circle to explore that area";

    const{loginUser, topicSummary, selectedTopicIndex, staticSummary, username} = props;
    const history = useHistory();
    const { t } = useTranslation();

    const[loading, setLoading] = React.useState(false);
    const topicName = React.useRef("Financial Wellness");
    const selectedAvatarIndex = React.useRef(0);
    const[topicIndex, setTopicIndex] = React.useState(0);
    const[topicSummaryState, setTopicSummaryState] = React.useState(topicSummary);
    const[selectedTopicIndexState, setSelectedTopicIndexState] = React.useState(selectedTopicIndex);
    

    // const[topicSummary, setTopicSummary] = React.useState(_topicSummary || null);

    React.useEffect(() => {
        console.log(topicSummary? topicSummary : "No topic summary");
        console.log(selectedTopicIndex? selectedTopicIndex : "No selected topic index");
        console.log(avatars[selectedTopicIndex]?.title? avatars[selectedTopicIndex].title : "No selected topic title");

        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        if(staticSummary){
            const topicId = 124;
            // const topicId = 49372; //Test env

            const topicScoresRequest = {};
            topicScoresRequest.topicId = topicId;
            if(username){
                topicScoresRequest.username=username;
            };

            const response = await getTopicSummary(topicScoresRequest, loginUser.jwtToken);
            setSelectedTopicIndexState(0);
            setTopicSummaryState(response);
            return;
        }
    };

    const getSummaryAndSteps = async (item, index) => {

        if(!item?.topicId){
            history.push({
                pathname: "/consumer/complete_wellness_plan",
            });
            return;
        }

        if(staticSummary){
            const topicScoresRequest = {};
            topicScoresRequest.topicId = item.topicId;
            if(username){
                topicScoresRequest.username=username;
            }
            const response = await getTopicSummary(topicScoresRequest, loginUser.jwtToken);
            setSelectedTopicIndexState(index);
            setTopicSummaryState(response);
            return;
        }

        topicName.current = item.title;
        selectedAvatarIndex.current = index;
        history.push({
            pathname: "/consumer/llm_choose",
            state: {
                topicId: item.topicId,
                selectedTopicIndex: index,
            }
        });
    };

    const avatars = [
        { icon: <ExploreOutlinedIcon />, topicId: 124, title: "Purpose & Legacy", color: "#7e5bab"},
        { icon: <TrackChangesIcon />, topicId: 122, title: "Financial Wellness", color: "#2fb44d" },
        { icon: <FavoriteBorderOutlinedIcon />, topicId: 120, title: "Health & Well-Being", color: "#ffc40c" },
        { icon: <GroupOutlinedIcon />, topicId: 119, title: "Relationships & Identity", color: "#f21d3c" },
        { icon: <LightModeOutlinedIcon />, topicId: 123, title: "Exploration & Leisure", color: "#007bff" },
        { icon: <TroubleshootOutlinedIcon />, topicId: null, title: "Create complete plan", color: "#180b5c" } // Example without action
    ];

    //For test env
    // const avatars = [
    //     { icon: <ExploreOutlinedIcon />, topicId: 49372, title: "Purpose & Legacy", color: "#7e5bab"},
    //     { icon: <TrackChangesIcon />, topicId: 49370, title: "Financial Wellness", color: "#2fb44d" },
    //     { icon: <FavoriteBorderOutlinedIcon />, topicId: 49371, title: "Health & Well-Being", color: "#ffc40c" },
    //     { icon: <GroupOutlinedIcon />, topicId: 119, title: "Relationships & Identity", color: "#f21d3c" },
    //     { icon: <LightModeOutlinedIcon />, topicId: 123, title: "Exploration & Leisure", color: "#007bff" },
    //     { icon: <TroubleshootOutlinedIcon />, topicId: null, title: "Create complete plan", color: "#180b5c" } // Example without action
    // ];

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "95%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "3rem"},
                }}
            >
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={2} sx={{height: "100%", overflow: "hidden" }}>
                        <Grid item xs={12} sx={{ width: "100%", position: "sticky", top: 0, zIndex: 10, paddingBottom: "0.5rem", paddingTop: "0.3rem", }}>
                            <Grid container sx={{display: "flex", justifyContent: "center"}}>
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start", textAlign: "left", mb: "1rem"}}>
                                    <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>{username ? `Client's Retirement Wellness Plan` : `${loginUser.firstName}'s Retirement Wellness Plan`}</Typography>
                                </Grid>
                                {avatars.map((item, index) => (
                                        <Grid item key={index} xs={2} sx={{ textAlign: "center", justifyContent: "center" }}>
                                            <Tooltip title={item.title} arrow>
                                                <Avatar
                                                onClick={() => item && getSummaryAndSteps(item, index)}
                                                sx={{
                                                    // backgroundColor: selectedTopicIndex === index ? "#00008b" : Colors.primary, // Change background color for selected avatar
                                                    backgroundColor: item.color,
                                                    width: {xs: selectedTopicIndexState === index ? "2.5rem" : "2rem", sm: selectedTopicIndexState === index ? "2.2rem" : "1.8rem", md: selectedTopicIndexState === index ? "3rem" : "2.5rem"}, // Slightly increase size for the selected avatar
                                                    height: {xs: selectedTopicIndexState === index ? "2.5rem" : "2rem", sm: selectedTopicIndexState === index ? "2.2rem" : "1.8rem", md: selectedTopicIndexState === index ? "3rem" : "2.5rem"}, // Slightly increase size for the selected avatar
                                                    cursor: "pointer",
                                                    boxShadow:
                                                    selectedTopicIndexState === index
                                                            ? "0 4px 10px rgba(0,0,0,0.3)" // Add a stronger shadow for selected avatar
                                                            : "0 2px 5px rgba(0,0,0,0.2)",
                                                    transition: "all 0.2s ease-in-out", // Smooth transitions for scaling and other effects
                                                    transform: selectedTopicIndexState === index ? "scale(1.1)" : "scale(1)", // Slight scaling effect
                                                    "&:hover": {
                                                        transform: "scale(1.15)", // A bit more scaling on hover
                                                    },
                                                }}
                                            >
                                                {item.icon}
                                            </Avatar>
                                        </Tooltip>
                                    </Grid>
                                ))}
                                {selectedTopicIndexState !== null && selectedTopicIndexState !== undefined &&
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Grid container direction="column">
                                            <Grid item sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                                                <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
                                                    {avatars[selectedTopicIndexState].title}
                                                </Typography>
                                            </Grid>
                                            {topicSummary &&
                                                <Grid item sx={{display: "flex", justifyContent: "start", textAlign: "left", mt: "0.5rem"}}>
                                                    <Typography variant="body2" sx={{ color: "black", fontWeight: "bold" }}>{t("TopicSummary.GraceReadyMsg")}</Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ 
                                height: "calc(100% - 12.5rem)", // 12.5rem accounts for: navbar 4rem, title (~2rem), avatars (~3rem), topic title (~2rem), grace message (~1.5rem)
                                overflowY: "auto", 
                                padding: "1rem",
                            }}
                        >
                            {topicSummaryState &&
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Card sx={{
                                        width: "100%",
                                        marginTop: "1rem",
                                        padding: "1.5rem",
                                        boxShadow: 3,
                                        borderRadius: "0.75rem",
                                        backgroundColor: "#f9f9f9",
                                    }}>
                                        <List sx={{width: "100%",}}>
                                            <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                                <Grid container sx={{display: "flex"}}>
                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                        <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                            Summary:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                        <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                                            {topicSummaryState.summary.split(/\r?\n/).map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            {topicSummaryState.keyGoals &&
                                                <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                                                    <Grid container sx={{display: "flex"}}>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                            <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                                Key Goals:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                            <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                                                {topicSummaryState.keyGoals.split(/\r?\n/).map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                            {topicSummaryState.primaryConcerns &&
                                                <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                                                    <Grid container sx={{display: "flex"}}>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                            <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                                Primary Concerns:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                            <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                                                {topicSummaryState.primaryConcerns.split(/\r?\n/).map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                            {topicSummaryState.opportunitiesForGrowth &&
                                                <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                                                    <Grid container sx={{display: "flex"}}>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                            <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                                Opportunities For Growth:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                            <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                                                {topicSummaryState.opportunitiesForGrowth.split(/\r?\n/).map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                            {topicSummaryState.graceTips &&
                                                <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                                                    <Grid container sx={{display: "flex"}}>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                            <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                                Grace Tips:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                            <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                                                {topicSummaryState.graceTips.split(/\r?\n/).map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                            {topicSummaryState.actionSteps &&
                                                <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                                                    <Grid container sx={{display: "flex"}}>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                            <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                                Action Steps:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                            <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify" }}>
                                                                {topicSummaryState.actionSteps.split(/\r?\n/).map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                            {topicSummaryState.joke &&
                                                <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                                                    <Grid container sx={{display: "flex"}}>
                                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                            <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify" }}>
                                                                {topicSummaryState.joke.split(/\r?\n/).map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                        </List>
                                    </Card>
                                </Grid>
                            }
                            {!topicSummaryState &&
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Card sx={{ 
                                        width: "100%",
                                        marginTop: "1rem",
                                        padding: "1.5rem",
                                        boxShadow: 3,
                                        borderRadius: "0.75rem",
                                        backgroundColor: "#f9f9f9",
                                    }}>
                                        <Grid container direction="column" sx={{display: "flex", justifyContent: "start"}}>
                                            <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                <Typography variant="body1" sx={{ color: "black", fontWeight: "bold" }}>Step 1: Retirement Wellness Assessment</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{display: "flex", justifyContent: "start", textAlign: "justify", mt: "0.7rem"}}>
                                                <Typography variant="body2" sx={{ color: "black" }}>
                                                    {summaryPendingText.split(/\r?\n/).map((line, index) => (
                                                        <React.Fragment key={index}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                            }
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}

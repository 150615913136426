import React from 'react';
import {Box, Grid, Typography, Card, List, ListItem} from "@mui/material";
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import {getTopicSummaries, createWellnessCompletePlan} from '../../../utilityFunctions/WellnessUtil';

// Replace the topicTitles object with an array of ordered topics
const orderedTopics = [
    { id: 124, title: "Purpose & Legacy", order: 0 },
    { id: 122, title: "Financial Wellness", order: 1 },
    { id: 120, title: "Health & Well-Being", order: 2 },
    { id: 119, title: "Relationships & Identity", order: 3 },
    { id: 123, title: "Exploration & Leisure", order: 4 },
    { id: null, title: "Emotional Wellness", order: 5 }
];

// Create a lookup object for easy title access
const topicTitlesLookup = Object.fromEntries(
    orderedTopics.map(topic => [topic.id, topic.title])
);

export default function CompleteWellnessPlan(props) {
    const { loginUser, username } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [allTopicSummaries, setAllTopicSummaries] = React.useState([]);

    React.useEffect(() => {
        const loadTopicSummaries = async () => {
            try {
                const summaries = await getTopicSummaries(username ? {username: username} : {}, loginUser.jwtToken);
                
                if (summaries) {
                    // Sort summaries based on the order in orderedTopics
                    summaries.sort((a, b) => {
                        const topicA = orderedTopics.find(t => t.id === a.topicId) || { order: Infinity };
                        const topicB = orderedTopics.find(t => t.id === b.topicId) || { order: Infinity };
                        return topicA.order - topicB.order;
                    });

                    setAllTopicSummaries(summaries);
                }
                else{
                    swal("Error", "No Plan Found", "ok");
                }
            } catch (error) {
                console.error("Error loading Plan:", error);
            } finally {
                setLoading(false);
            }
        };

        // loadTopicSummaries();

        const createCompletePlan = async () => {
            const response = await createWellnessCompletePlan(username ? {username: username} : {}, loginUser.jwtToken);
            setLoading(false);
            console.log(response);

            if(!response){
                swal("Error", "No Plan Found", "ok");
            }

        }

        createCompletePlan();
    }, [loginUser.jwtToken]);

    const renderSummarySection = (title, content) => {
        if (!content) return null;
        
        return (
            <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                <Grid container sx={{display: "flex"}}>
                    {title ?
                        <>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                                <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
                                    {`${title}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                                <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                    {content.split(/\r?\n/).map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                            <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                {content.split(/\r?\n/).map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </ListItem>
        );
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: {xs: "90%", sm: "80%", md: "80%"}, height: "95%", textAlign: "left", marginTop: {xs: "1.3rem", sm: "3rem"}}}>
                {loading ? loaderAnimation : (
                    <Grid container direction="row" spacing={2} sx={{height: "100%", overflow: "auto"}}>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start", textAlign: "left", mb: "1rem"}}>
                            <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
                                {`${loginUser.firstName}'s Complete Retirement Wellness Plan`}
                            </Typography>
                        </Grid>
                        
                        {allTopicSummaries.map((topicSummary, index) => (
                            <Grid item xs={12} key={index}>
                                <Card sx={{width: "100%", padding: "1.5rem", boxShadow: 3, borderRadius: "0.75rem", backgroundColor: "#f9f9f9", marginBottom: "2rem"}}>
                                    <Typography variant="h6" sx={{ color: "black", fontWeight: "bold", mb: 2 }}>
                                        {`${orderedTopics.find(topic => topic.id === topicSummary.topicId).title} Overview` }
                                    </Typography>
                                    <List sx={{width: "100%"}}>
                                        {renderSummarySection(null, topicSummary.summary)}
                                        {renderSummarySection("Key Goals", topicSummary.keyGoals)}
                                        {renderSummarySection("Primary Concerns", topicSummary.primaryConcerns)}
                                        {renderSummarySection("Opportunities For Growth", topicSummary.opportunitiesForGrowth)}
                                        {renderSummarySection("Grace Tips", topicSummary.graceTips)}
                                        {renderSummarySection("Action Steps", topicSummary.actionSteps)}
                                        {topicSummary.joke && (
                                            <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "1.5rem"}}>
                                                <Grid container sx={{display: "flex"}}>
                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                        <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify" }}>
                                                            {topicSummary.joke.split(/\r?\n/).map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        )}
                                    </List>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </Box>
    );
} 
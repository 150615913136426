import React from 'react';
import {Box, Grid, TextField, Typography, Button, Autocomplete, List, ListItem} from "@mui/material";
import swal from "sweetalert";
import {saveAdvisorClientMapping, getAllClientAdvisorsInfo, getSingleUser, getAllAdvisorsUsernames} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

const newAdvisorRequest={
    advisorUsername: ""
};

export default function ConsumerAdvisorMapping(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[usernames, setUsernames] = React.useState(null);
    const[username, setUsername] = React.useState(null);

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        
        setLoading(true);
        const response = await getAllAdvisorsUsernames(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get advisors",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setUsernames(response);
    };

    const usernameChange = async (e, value) => {
        if(!value){
            return;
        }

        setUsername(value);
        newAdvisorRequest.advisorUsername = value.username;

    };

    const askNewAdvisor = () => {
        swal({
            title: "Adding Advisor",
            text: "Are you sure you want to add this advisor?",
            icon: "info",
            button: "OK",
        }).then(() => {
            addNewAdvisor(username);
            return;
        });
    };

    const addNewAdvisor = async () => {
        setLoading(true);
        const response = await saveAdvisorClientMapping(newAdvisorRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to add advisor",
                icon: "error",
                button: "OK",
            });
            return;
        };

        swal({
            title: "Success",
            text: "Advisor added successfully",
            icon: "success",
            button: "OK",
        }).then(() => {
            history.push("/consumer/llm_choose");
        });
    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={1} sx={{justifyContent: "center"}}>
                    {usernames &&
                            <>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Autocomplete
                                    sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                    options={usernames}
                                    getOptionLabel={(option) => option.username}
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"username not found"}
                                    fullWidth
                                    onChange={usernameChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Username" variant="standard" />
                                    )}
                                />
                            </Grid>
                            {username &&
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={askNewAdvisor} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Add Advisor
                                </Button>
                            </Grid>
                            }
                            </>
                    }
                    </Grid>
                }
            </Box>
        </Box>
    );
}

import React from 'react';
import {Box, Grid, TextField, Typography, Button, List, ListItem, Avatar, CircularProgress, LinearProgress} from "@mui/material";
import swal from "sweetalert";
import {manageQuestionsChatFlow, createQuestionsChatSession, updateChatSessionSystemPrompts} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAudioStream } from '../speechai/useAudioStream';
import { useTranslation } from 'react-i18next';
import TopicSummary from '../summaries/TopicSummary';
import { useHistory } from "react-router-dom";

const newSessionRequest={};
const completionsRequest={};

let updateChatSessionEnabled = true;

export default function LlmQuestionsChat(props) {

    const { loginUser } = props;
    const history = useHistory();
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [chatLoading, setChatLoading] = React.useState(false);

    const { startRecording, stopRecording, playReceivedAudio, stopPlayingAudio, isSourceActive, } = useAudioStream();
    const [isRecording, setIsRecording] = React.useState(false);

    const[userMessage, setUserMessage] = React.useState("");
    const[chatHistory, setChatHistory] = React.useState(null);
    const[newSession, setNewSession] = React.useState(null);

    //Trigger scroll when chatHistory changes
    const chatEndRef = React.useRef(null);
    const isFirstRender = React.useRef(true);

    React.useEffect(() => {
        const createSession = async() => {
            createNewSession();
        }
        createSession();

        return () => {
            
            const cleanup = async () => {
                console.log("Calling completed session api");
                if(updateChatSessionEnabled === true){
                    await updateChatSession(); // Closing the chat session
                }
            };
            // cleanup(); // Call the async cleanup function
            
        };
    }, []);

    const createNewSession = async() => {
        
        newSessionRequest.reference = props.location?.state?.reference || "form_system_initial";
        newSessionRequest.topicId = props.location?.state?.topic?.id || props.location?.state?.topicId || 122;

        setLoading(true);
        const response = await createQuestionsChatSession(newSessionRequest, loginUser.jwtToken);
        setLoading(false);

        if (!response) {
            updateChatSessionEnabled = false;
            swal({ title: "Error", text: "Failed to create new session", icon: "error", button: "OK" });
            return;
        }

        else if(response.chatSession.status === "completed") {
            updateChatSessionEnabled = false;
            // swal({ 
            //     title: "Completed", 
            //     text: t("LlmQuestionsChat.topicCompletedMessage"), 
            //     icon: "success", 
            //     button: "OK" }).then(() => {
            //         history.push({
            //             pathname: "/consumer/llm_choose",
            //             state:{
            //                 topicId: newSessionRequest.topicId,
            //                 selectedTopicIndex: props?.location?.state?.selectedTopicIndex
            //             }
            //         });
            // });
            history.push({
                pathname: "/consumer/llm_choose",
                state:{
                    topicId: newSessionRequest.topicId,
                    selectedTopicIndex: props?.location?.state?.selectedTopicIndex
                }
            });
            
            return
        }

        setNewSession(response.chatSession);
        completionsRequest.sessionId = response.chatSession.id;

        if(response.chatSession.chatSessionData && response.chatSession.chatSessionData.length > 0) {
            setChatHistory(response.chatSession);
        }
    
    };

    const userMessageChange = async(e) => {
        setUserMessage(e.target.value);
        completionsRequest.userMessage = e.target.value;
    };

    const getChatCopletion = async() => {

        setUserMessage("");
        
        completionsRequest.topicId = props.location?.state?.topic?.id || props.location?.state?.topicId || 122;
        completionsRequest.reference = props.location?.state?.reference || "form_system_initial";
        
        setChatLoading(true);
        const response = await manageQuestionsChatFlow(completionsRequest, loginUser.jwtToken);
        setChatLoading(false);

        cleanAudioMessage();

        if (!response) {
            swal({ title: "Error", text: "Failed to get chat completion", icon: "error", button: "OK" });
            return;
        }

        else if(response.chatSession.status === "completed") {

            swal({ 
                title: "Completed", 
                text: t("LlmQuestionsChat.topicCompletedMessage"), 
                icon: "success", 
                button: "OK" 
            }).then(() => {
                history.push({
                    pathname: "/consumer/llm_choose",
                    state:{
                        topicId: completionsRequest.topicId,
                        selectedTopicIndex: props?.location?.state?.selectedTopicIndex
                    }
                });
            });
            
            return
        }

        setChatHistory(response.chatSession);

        if(response.base64AudioData){
            playReceivedAudio(response.base64AudioData);
        }
        
    };

    const updateChatSession = async() => {

        const updateSessionRequest ={
            sessionId: completionsRequest.sessionId ,
            reference: "grace_system_final",
            userMessage: "Conversation completed",
        }

        setLoading(true);
        const response = await updateChatSessionSystemPrompts(updateSessionRequest, loginUser.jwtToken);
        setLoading(false);

        // if (!response) {
        //     swal({ title: "Error", text: "Failed to update chat session", icon: "error", button: "OK" });
        //     return;
        // }

        // setChatHistory(response);
    };


    const scrollToBottom = () => {
        if (chatEndRef.current) {
            // Scroll the last message to be visible but not necessarily at the top
            chatEndRef.current.scrollIntoView({ 
                behavior: "smooth", 
                block: "center" // This will position the element at the bottom of the viewport
            });
        }
    };

    React.useEffect(() => {
        if (chatHistory && isFirstRender.current === true) {
            isFirstRender.current = false;
            return;
        }
        
        scrollToBottom();
    }, [chatHistory]);

    const handleStartRecording = async () => {
        setIsPlaying(false);
        setIsRecording(true);
        await startRecording();
    };
    
    const handleStopRecording = async () => {
        setIsPlaying(true);
        setIsRecording(false);
        const audioRequest = await stopRecording();

        console.log(audioRequest);

        completionsRequest.audioData = audioRequest.audioData;
        completionsRequest.sampleRate = audioRequest.sampleRate;
        completionsRequest.isSpeechRequest = true;


        
        getChatCopletion();
        
    };

    const cleanAudioMessage = () => {
        completionsRequest.audioData = null;
        completionsRequest.sampleRate = null;
        completionsRequest.isSpeechRequest = false;
    };

    const handleStopPlayingAudio = async () => {
        stopPlayingAudio();
        setIsPlaying(false);
    };

    const[isPlaying, setIsPlaying] = React.useState(false);

    React.useEffect(() => {
        // Update state whenever sourceRef.current changes
        console.log("isSourceActive: ", isSourceActive);
        if(isSourceActive === false){
            setIsPlaying(false);
        }
    }, [isSourceActive]);
    
    //Functions for buffered loader
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);
    
    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress === 100) {
            setProgress(0);
            setBuffer(10);
            } else {
            setProgress((prevProgress) => prevProgress + 1);
            if (buffer < 100 && progress % 5 === 0) {
                const newBuffer = buffer + 1 + Math.random() * 10;
                setBuffer(newBuffer > 100 ? 100 : newBuffer);
            }
            }
        };
    }, [progress, buffer]); // Depend on progress and buffer
        
    React.useEffect(() => {
        if (!chatLoading) {
            // Reset progress and buffer when chatLoading is false
            setProgress(0);
            setBuffer(10);
            return; // Stop execution if chatLoading is false
        }
        
        const timer = setInterval(() => {
            progressRef.current();
        }, 100);
        
        return () => {
            clearInterval(timer);
        };
    }, [chatLoading]);
    
    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: {xs: "90%", sm: "90%", md: "90%"}, height: "90%", textAlign: "center", marginTop: { xs: "1.3rem", sm: 0 }}}>
                {loading ? loaderAnimation :
                    <Grid container direction="row" sx={{justifyContent: "center"}}>
                        {/* <Grid item xs={12} sx={{display: "flex", justifyContent: "start", mt: "4.5rem", textAlign: "left"}}>
                            <Typography>
                                <Box component="span" sx={{ fontSize: {xs: "1.7rem", sm: "2rem"}, fontWeight: "bold" }}>{`Hello ${loginUser.firstName}!`}</Box> 
                                <Box component="span" sx={{ fontSize: {xs: "1.1rem", sm: "1.4rem"}, fontWeight: "bold", ml: "10px" }}>Welcome to Your Retirement Wellness Journey</Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "start", mt: "0.5rem", textAlign: "left"}}>
                            <Typography variant="body1" sx={{ color: "black", fontWeight: "bold" }}>Step 1: Retirement Wellness Assessment</Typography>
                        </Grid> */}
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", mt:"4.8rem"}}>
                            <Grid container
                                sx={{
                                    display: "flex", 
                                    justifyContent: "center",
                                    padding: {xs: "0.8rem", sm: "1rem"},
                                    borderRadius: "0.75rem",
                                    backgroundColor: "#f9f9f9",
                                    gap: {xs: "0.5rem", sm: "1.8rem"},
                                }}
                            >
                                {/*Chat section*/}
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center", padding: "1rem", borderRadius: "0.75rem", backgroundColor: "white"}}>
                                    <Grid container sx={{height: { xs: '80vh', sm: '80vh', md: '80vh'}, overflowY: 'auto', marginTop: {sx: "1rem", sm: "1rem", md: "1.5rem"}}}>
                                        <Grid item xs={3} sm={3} sx={{ display: "flex", justifyContent: "start" }}>
                                            <Grid container direction="column" sx={{display: "flex", justifyContent: "center"}}>
                                                <Grid item sx={{display: "flex", justifyContent: "center"}}>
                                                    <Avatar
                                                        src="/images/grace-icon.png" // Direct relative path to the image in the public folder
                                                        alt="Grace Icon"
                                                        sx={{
                                                            width: { xs: "65px", sm: "100px" }, 
                                                            height: { xs: "65px", sm: "100px" },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item sx={{display: "flex", justifyContent: "center", mt: "0.5rem"}}>
                                                    <Typography variant="body1" sx={{ color: "black", fontWeight: "bold" }}>{"Grace\u2122"}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9} sm={9} sx={{ display: "flex", justifyContent: "end", alignItems: "start", "&:hover": {backgroundColor: "transparent"}}}>
                                            <Button 
                                                sx={{ 
                                                    pr: "2.5rem",
                                                    color: "black", 
                                                    textTransform: "none", 
                                                    fontSize: {xs: "0.7rem", sm: "0.8rem"},
                                                    transition: "transform 0.2s",
                                                    "&:hover": {
                                                        backgroundColor: "transparent",
                                                        transform: "scale(1.05)"
                                                    }
                                                }}
                                                startIcon={<InfoOutlinedIcon style={{ fontSize: "2rem" }} />}
                                                onClick={() => window.open("https://userguidempk-rrd73ym.gamma.site/", "_blank")}
                                            >
                                                {t("LlmQuestionsChat.howItWorks")}
                                            </Button>
                                        </Grid>
                                        {chatHistory && chatHistory.chatSessionData &&
                                            <Grid item xs={12} sx={{mt: {xs: "1.5rem", sm: 0}}}>
                                                <List sx={{ width: "95%", marginTop: { xs: "2rem", sm: "2rem", md: "2rem" } }}>
                                                {chatHistory.chatSessionData
                                                    .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) // Sort by startTime (latest first)
                                                    .map((data, index) => (
                                                        <Grid key={data.id} container spacing={1}>
                                                            {data.userMessage &&
                                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}>
                                                                    <ListItem disablePadding sx={{ width: {xs: "75%", sm: "60%"}, marginBottom: "1.5rem", bgcolor: "#d0ebff", borderRadius: "16px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "0.75rem 1rem"}}>
                                                                        {/* <Typography variant="body2" sx={{ fcolor: "black", whiteSpace: "pre-wrap" }}>{data.userMessage}</Typography> */}
                                                                        <Typography variant="body2" sx={{ color: "black" }}>
                                                                            {data.userMessage.split(/\r?\n/).map((line, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    {line}
                                                                                    <br />
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </Typography>
                                                                    </ListItem>
                                                                </Grid>
                                                            }
                                                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", textAlign: "start" }}>
                                                                <ListItem disablePadding sx={{ width: {xs: "90%", sm: "95%", md: "65%"}, marginBottom: "1.5rem", bgcolor: "grey.200", borderRadius: "16px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "0.75rem 1rem" }}>
                                                                    {/* <Typography variant="body2" sx={{ color: "black", whiteSpace: "pre-wrap" }}>{data.assistantMessage}</Typography> */}
                                                                    <Typography variant="body2" sx={{ color: "black" }}>
                                                                        {data.assistantMessage.split(/\r?\n/).map((line, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {line}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </Typography>
                                                                </ListItem>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    ))}
                                                    {/* Scroll anchor */}
                                                    <div ref={chatEndRef} />
                                                </List>
                                            </Grid>
                                        }
                                        {chatLoading ? 
                                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", width: "100%"}}>
                                                {/* <CircularProgress />  */}
                                                <Box sx={{ width: {xs: "50%", sm: "30%"} }}>
                                                    <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                                                </Box>
                                            </Grid>
                                        :
                                        <>
                                            <Grid item xs={9} sm={8} md={8} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "2rem"}}>
                                                <TextField
                                                    value={userMessage}
                                                    onChange={(e) => userMessageChange(e)}
                                                    placeholder="Type your message or click microphone to speak..."
                                                    multiline
                                                    minRows={1}
                                                    maxRows={5} // Adjust to set maximum height
                                                    fullWidth
                                                    sx={{
                                                            width: {xs:"95%", sm: "85%"},
                                                        '& .MuiInputBase-root': {
                                                            borderRadius: '16px', // Optional: Add rounded edges
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            fontSize: { xs: "0.8rem", sm: "0.8rem", md: "1rem" }, // Input text font size
                                                        },
                                                        '& .MuiInputBase-input::placeholder': {
                                                            fontSize: { xs: "0.8rem", sm: "0.8rem", md: "1rem" }, // Placeholder text font size
                                                        },
                                                        
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' && !e.shiftKey) {
                                                            e.preventDefault(); // Prevents new line on Enter key
                                                            getChatCopletion(); // Calls the function when Enter is pressed
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            {userMessage &&
                                                <Grid item xs={3} sm={4} md={4} sx={{display: "flex", alignItems: "center", justifyContent: {xs:"center", sm: "start"}, marginBottom: "2rem"}}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{backgroundColor: Colors.secondary, color: Colors.white, borderRadius: '16px'}}
                                                        onClick={getChatCopletion}
                                                    >
                                                        <PlayCircleFilledIcon sx={{fontSize: {xs: "1.5rem", sm: "2rem"}}}/>
                                                    </Button>
                                                </Grid>
                                            }
                                            {!userMessage && isRecording === false && isPlaying===false &&
                                                <Grid item xs={3} sm={4} md={4} sx={{display: "flex", alignItems: "center", justifyContent: {xs:"center", sm: "start"}, marginBottom: "2rem"}}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{backgroundColor: `${Colors.secondary} !important`, color: Colors.white, borderRadius: '16px'}}
                                                        onClick={handleStartRecording}
                                                    >
                                                        <MicIcon sx={{fontSize: {xs: "1.5rem", sm: "2rem"}}}/>
                                                    </Button>
                                                </Grid>
                                            }
                                            {!userMessage && isRecording === true &&
                                                <Grid item xs={3} sm={4} md={4} sx={{display: "flex", alignItems: "center", justifyContent: {xs:"center", sm: "start"}, marginBottom: "2rem"}}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{backgroundColor: `red !important`, color: Colors.white, borderRadius: '16px'}}
                                                        onClick={handleStopRecording}
                                                    >
                                                        <MicIcon sx={{fontSize: {xs: "1.5rem", sm: "2rem"}}}/>
                                                    </Button>
                                                </Grid>
                                            }
                                            {isPlaying===true &&
                                                <Grid item xs={3} sm={4} md={4} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "2rem"}}>
                                                    <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: {xs:0, sm: "1.2rem"}}}>
                                                        <Grid item xs={12} sm={4} md={4} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: {xs: "0.5rem", sm: "2rem"}}}>
                                                            <Button
                                                                variant="contained"
                                                                sx={{backgroundColor: `${Colors.secondary} !important`, color: Colors.white, borderRadius: '16px'}}
                                                                onClick={handleStartRecording}
                                                            >
                                                                <MicIcon sx={{fontSize: {xs: "1.2rem", sm: "2rem"}}}/>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8} sx={{display: "flex", alignItems: "center", justifyContent: {xs:"center", sm: "start"}, marginBottom: {xs: 0, sm: "2rem"}}}>
                                                            <Button
                                                                variant="contained"
                                                                sx={{backgroundColor: `red !important`, color: Colors.white, borderRadius: '16px'}}
                                                                onClick={handleStopPlayingAudio}
                                                            >
                                                                <StopIcon sx={{fontSize: {xs: "1.2rem", sm: "2rem"}}}/>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>
                                        }

                                        <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: "start", mt: "0rem", textAlign: "justify"}}>
                                            <Typography variant="body2" sx={{ color: "black", fontSize: {xs: "0.7rem", sm: "0.7rem"}}}>{t("LlmQuestionsChat.disclaimer")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", justifyContent: {xs: "center", sm: "end"}, alignItems: "start", mt: "0rem"}}>
                                            <Button 
                                                sx={{ color: "black", textTransform: "none", fontSize: {xs: "0.7rem", sm: "0.8rem"}}}
                                                startIcon={<ListAltIcon style={{ fontSize: "2rem" }} />}
                                                onClick={() => history.push("/consumer/manual_plan")}
                                                // onClick={() => history.push("/retirement_plan/topics")}
                                            >
                                                {t("LlmQuestionsChat.quickForm")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/*Avatar section*/}
                                <Grid item xs={12} sm={5} md={5} sx={{display: {xs: "flex", sm: "flex"}, justifyContent: "center", position: "sticky", top: "4rem", height: "calc(100vh - 4rem)", paddingRight: "1.5rem", paddingLeft: "1.5rem",  borderRadius: "0.75rem", backgroundColor: "white"}}>
                                    <TopicSummary 
                                            loginUser={loginUser}
                                            selectedTopicIndex={props?.location?.state?.selectedTopicIndex}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    );
}

import React from 'react';
import { Box, Grid, TextField, Typography, Button, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Colors from "../../components/ui/Colors";
import { t } from 'i18next';
import { loaderAnimation } from '../../Api';
import dayjs from 'dayjs';
import { createClient } from '../../utilityFunctions/WellnessUtil';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

const onboardingInformation = {
    firstName: "",
    lastName: "",
    birthDateString: "",
    email: "",
    alreadyRetired: ""
};


export default function ClientCreation(props) {
    const {loginUser, setLoading} = props;
    const history = useHistory();
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [email, setEmail] = React.useState("");
    const [retired, setRetired] = React.useState("");

    const alreadyRetired = [
        {
            type: "yes",
            label: t("PersonalInformation.alreadyRetired.yes")
        },
        {
            type: "no",
            label: t("PersonalInformation.alreadyRetired.no")
        }
    ];

    const retiredChange = (e) => {
        setRetired(e.target.value);
        onboardingInformation.alreadyRetired=e.target.value;
    }

    const firstNameChange = (e) => {
        setFirstName(e.target.value);
        onboardingInformation.firstName=e.target.value;
    }

    const lastNameChange = (e) => {
        setLastName(e.target.value);
        onboardingInformation.lastName=e.target.value;
    }

    const emailChange = (e) => {
        setEmail(e.target.value);
        onboardingInformation.email=e.target.value;
    }

    const handleDateChange = (date) => {
        // setSelectedDate(dayjs(date).format('YYYYMMDD'));
        setSelectedDate(date);
        onboardingInformation.birthDateString=dayjs(date).format('YYYYMMDD');
    };

    const handleSubmit = async() => {
        try {
            setLoading(true);
            const response = await createClient(onboardingInformation, loginUser.jwtToken);

            if(!response){
                swal("Client not created", {
                    icon: "error",
                });
                return;
            }
            
            swal("Client created", {
                icon: "success",
            }).then(() => {
                history.push({
                    pathname: "/consumer/manual_plan",
                    state: {
                        username: onboardingInformation.email,
                    }
                });
            });
        } catch (error) {
            console.error(error);
            swal("Error creating client", {
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '2rem',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        height: '100%'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{display: "flex", justifyContent: "start", flexDirection: "column", textAlign: "start"}}>
                        <Typography variant="h6" sx={{ mb: "0.5rem" }}>Create Client Profile</Typography>
                        <Typography variant="body2" sx={{ mb: "1rem" }}>This helps personalize your client's plan. Need help?</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "start", "&:hover": {backgroundColor: "transparent"}}}>
                    <Button 
                        sx={{ 
                            pr: "2.5rem",
                            color: "black", 
                            textTransform: "none", 
                            fontSize: {xs: "0.7rem", sm: "0.8rem"},
                            transition: "transform 0.2s",
                            "&:hover": {
                                backgroundColor: "transparent",
                                transform: "scale(1.05)"
                            }
                        }}
                        startIcon={<InfoOutlinedIcon style={{ fontSize: "2rem" }} />}
                        onClick={() => window.open("https://userguidempk-rrd73ym.gamma.site/", "_blank")}
                    >
                        {"Click here."}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => firstNameChange(e)}
                />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => lastNameChange(e)}
                />
                </Grid>

                <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => emailChange(e)}
                />
                </Grid>

                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <TextField
                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                        variant="standard"
                        fullWidth={true}
                        select
                        label={<Typography variant="body2">{t("PersonalInformation.retirementStatus")}</Typography>}
                        value={retired}
                        onChange={(e)=>retiredChange(e)}
                        autoComplete="off"
                    >
                        {alreadyRetired.map((typeEl) => (
                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                {typeEl.label}
                            </MenuItem>
                        ))}
                    </TextField> 
                </Grid>

                <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                    label="Date of Birth"
                    inputFormat="MM/DD/YYYY"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Button 
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    size="big" 
                    sx={{backgroundColor: Colors.secondary, marginTop: "2rem", height: "3rem", width: {xs: "50%", sm: "30%", md: "26%"}, borderRadius: "100px 100px 100px 100px"}}
                >
                    Create Client
                </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

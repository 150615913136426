import React from 'react';
import { Box, Grid } from "@mui/material";
import ConsumerPlan from './ConsumerPlan';
import ConsumerPlanDetail from './ConsumerPlanDetail';

export default function CombinedConsumerPlan(props) {
    const [selectedTopic, setSelectedTopic] = React.useState(null);
    const [username, setUsername] = React.useState(props.location?.state?.username || null);

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <Grid container sx={{alignItems: "start", height: "100%"}}>
                <Grid item xs={12} sm={4} md={3} sx={{ borderRight: '1px solid #e0e0e0', alignItems: "start", height: "100%"}}>
                    <ConsumerPlan 
                        {...props} 
                        isEmbedded={true}
                        onTopicSelect={(topic) => setSelectedTopic(topic)}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={9} sx={{alignItems: "start", height: "100%"}}>
                    {selectedTopic && (
                        <ConsumerPlanDetail 
                            {...props}
                            isEmbedded={true}
                            location={{ 
                                state: { 
                                    topic: selectedTopic,
                                    username: username
                                } 
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
} 
import React from 'react';
import {Box, Grid, TextField, Autocomplete, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function ClientPlanForm(props) {
    const {loginUser, usernames} = props;
    const history = useHistory();

    const usernameChange = (e, value) => {
        if(!value) return;
        history.push({
            pathname: "/consumer/manual_plan",
            state: { username: value.username }
        });
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "95%", height: "90%", textAlign: "center", marginTop: { xs: "1.3rem", sm: 0 } }}>
                <Grid container direction="row" spacing={1}>
                    {usernames && (
                        <>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body2" sx={{fontWeight: "bold", fontSize: "0.8rem"}}>{"If your client already part of the My Plan Keeper\u2122 network"}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Autocomplete
                                sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                options={usernames}
                                getOptionLabel={(option) => option.username}
                                clearOnEscape
                                autoComplete
                                noOptionsText="Username not found"
                                fullWidth
                                onChange={usernameChange}
                                renderInput={(params) => (
                                    <TextField {...params} label="Enter the email address" variant="standard" />
                                )}
                            />
                        </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

import React from 'react';
import { Box, Grid, Typography, List, ListItem, Checkbox, FormControlLabel, Button, useMediaQuery, Divider, Avatar } from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import {calculateUserTopicScores, calculateProgressScores} from '../../utilityFunctions/WellnessUtil';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, RadialBarChart, RadialBar, } from 'recharts';
import TopicSummary from './summaries/TopicSummary';
import { loaderAnimation } from '../../Api';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const COLORS = ['#f21d3c', '#ffc40c', '#2fb44d', '#007bff', '#7e5bab', '#FAF4FF', '#F7D3ED'];

let totalValue;

export default function ConsumerPlanCalculation(props) {
    const {loginUser} = props;

    let history = useHistory();

    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(false);

    const topicColors = [
        {
            topicId: 119, //Relationships
            color: "#f21d3c",
        },
        {
            topicId: 120, //Health
            color: "#ffc40c",
        },
        {
            topicId: 122, //Financial
            color: "#2fb44d",
        },
        {
            topicId: 123, //Exploration
            color: "#007bff",
        },
        {
            topicId: 124, //Purpose
            color: "#7e5bab",
        },

    ]

    const [data, setData] = React.useState(null);
    const [progressData, setProgressData] = React.useState(null);
    const [progressEnabled, setProgressEnabled] = React.useState(false);

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();

        const loadProgressInitialValues = async () => {
            await progressInitialValues();
        }
        // loadProgressInitialValues();
    }, []);

    const initialValues = async () => {

        const topicScoresRequest = {};
        if(props.location?.state?.username){
            topicScoresRequest.username=props.location?.state?.username;
        };
        setLoading(true);
        const response = await calculateUserTopicScores(topicScoresRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }
        
        totalValue = response.reduce((acc, entry) => acc + entry.value, 0);
        console.log(totalValue);

        setData(response);
        return response;
    };

    const progressInitialValues = async () => {
        
        setLoading(true);
        const response = await calculateProgressScores(loginUser.jwtToken);
        setLoading(false);
    
        if(!response){
            swal({
                title: "Error",
                text: "Failed to get progress score",
                icon: "error",
                button: "OK",
            });
            return;
        }

        const totalScore = response?.totalScore || 0;
        const totalClientScore = response?.totalClientScore || 0;

        if(totalScore === 0){
            setProgressData(
                [{
                    name: "Progress",
                    value: 0,
                    fill: "#4caf50",
                }]
            )
            return;
        }

        const progress = Math.round((totalClientScore / totalScore) * 100);
        console.log(progress);


        setProgressData([
            {
              name: `${progress}%`,
              value: progress,
              fill: Colors.primary, // Example green color for progress
            },
            {
            //   name: "",
              value: 100 - progress,
              fill: "#f0f0f0", // Background color
            },
        ]);
    };

    // Define breakpoints
    const isXs = useMediaQuery('(max-width:599px)');
    const isSm = useMediaQuery('(min-width:600px) and (max-width:899px)');
    const isMd = useMediaQuery('(min-width:900px) and (max-width:1199px)');

    // Adjust height based on screen size
    const height = isXs ? 150 : isSm ? 200 : isMd ? 200 : 250;
    const progressHeight = isXs ? 150 : isSm ? 200 : isMd ? 200 : 200;



    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: {xs: "start", sm: "start", md: "center"}, width: "95%", height: "90%", textAlign: "center", marginTop: { xs: "4.5rem", sm: "2rem", md: "0" } }}>
                {loading ? loaderAnimation :
                    <>
                    {!props.location?.state?.username &&
                        <Box 
                            sx={{ display: { xs: "none", sm: "flex" }, position: "absolute", top: 15, right: 10, flexDirection: "row", alignItems: "center", zIndex: 1, maxWidth: "22rem", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", borderRadius: "8px", cursor: "pointer", padding: "8px", backgroundColor: "white", transition: "box-shadow 0.3s ease", '&:hover': { boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)" } }}
                            onClick={() => history.push('/consumer/llm_choose')}
                        >
                            <Avatar
                                src="/images/grace-icon.png"
                                alt="Grace Icon"
                                sx={{ width: 40, height: 40, mr: 2 }}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>{t("ConsumerPlanCalculation.needAction")}</Typography>
                                <Typography variant="caption" sx={{ fontSize: "0.6rem" }}>{t("ConsumerPlanCalculation.needActionExplanation")}</Typography>
                            </Box>
                        </Box>
                    }
                        <Grid container direction="row" spacing={1} sx={{marginTop: "4rem"}}>
                            <Grid item xs={12}>
                                <Typography variant="h4" sx={{fontWeight: "bold" }}>{"MyLifePortfolio\u2122"}</Typography>
                            </Grid>
                            {progressData && progressEnabled &&
                            <>
                                <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" sx={{ marginTop: "0.2rem", fontWeight: "bold" }}>Plan Progress</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ResponsiveContainer width="100%" height={progressHeight}>
                                                <RadialBarChart
                                                width={100}
                                                height={100}
                                                innerRadius="70%"
                                                outerRadius="100%"
                                                barSize={30}
                                                data={progressData}
                                                startAngle={180}
                                                endAngle={0}
                                                >
                                                <RadialBar
                                                    minAngle={15}
                                                    clockWise
                                                    dataKey="value"
                                                    cornerRadius={10} // Smooth bar edges
                                                />
                                                {/* <Legend
                                                    iconSize={10}
                                                    layout="vertical"
                                                    verticalAlign="middle"
                                                    wrapperStyle={{ bottom: 10 }}
                                                /> */}
                                                </RadialBarChart>
                                            </ResponsiveContainer>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" sx={{ marginTop: 0, fontWeight: "bold" }}>{`${progressData[0].value}%`}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" sx={{ marginTop: 0}}>Keep up the great work.</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            
                                <Grid item xs={12} sm={0} md={0} sx={{display: {sm: "none", md: "none"}}}>
                                    <Divider sx={{marginTop: "1.5rem"}} />
                                </Grid>
                            </>
                            }
                            {data && data.length > 0 &&
                                <Grid item xs={12} sm={12} md={6} sx={{display: "flex", justifyContent: "center", mt: {xs: "1.6rem", sm: "0.5rem"} }}>
                                    <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Grid item xs={12}>
                                            <Grid container direction="column" alignItems="center">
                                                <Grid item sx={{display: "flex", textAlign: "left"}}>
                                                    <Typography variant="body1" sx={{ marginTop: "0.2rem", fontWeight: "bold" }}>{t("ConsumerPlanCalculation.pieTitle")}</Typography>
                                                </Grid>
                                                <Grid item sx={{display: "flex", textAlign: "justify", width: {xs: "100%", sm: "55%", md: "45%"}}}>
                                                    <Typography variant="body2" sx={{}}>{t("ConsumerPlanCalculation.pieSubtitle")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ResponsiveContainer width="100%" height={height}>
                                                <PieChart>
                                                    <Pie
                                                    data={data}
                                                    cx="50%"
                                                    cy="50%"
                                                    labelLine={false}
                                                    label={({ cx, cy, midAngle, innerRadius, outerRadius, name, value }) => {
                                                        const RADIAN = Math.PI / 180;
                                                        const radius = outerRadius + 20; // Move label 20px outside the outer edge
                                                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                                        // const percentage = (value * 100).toFixed(2);
                                                        const percentage = Math.round(value * 100);

                                                        return (
                                                        <g>
                                                            {/* Name Text */}
                                                            <text
                                                                x={x}
                                                                y={y - 10} // Adjust y position for stacking
                                                                fill="#333"
                                                                fontSize={window.innerWidth < 600 ? 6 : 11}
                                                                fontWeight="bold"
                                                                textAnchor={x > cx ? 'start' : 'end'}
                                                                dominantBaseline="central"
                                                            >
                                                                {name}
                                                            </text>
                                                            {/* Percentage Text */}
                                                            <text
                                                                x={x}
                                                                y={y + 11} // Adjust y position to display below the name
                                                                fill="#888"
                                                                fontSize={11}
                                                                textAnchor={x > cx ? 'start' : 'end'}
                                                                dominantBaseline="central"
                                                            >
                                                                {`${percentage}%`}
                                                            </text>
                                                        </g>
                                                        );
                                                    }}
                                                    outerRadius={window.innerWidth < 600 ? 40 : 80}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                    >
                                                    {data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={topicColors.find(topic => topic.topicId === entry.topicId)?.color || COLORS[index % COLORS.length]} />
                                                    ))}
                                                    </Pie>

                                                    {/* Custom Legend */}
                                                    {/* <Legend
                                                        content={({ payload }) => (
                                                            <ul style={{ listStyle: 'none', padding: 0, margin: 0, fontSize: '14px', color: '#555'}}>
                                                            {payload.map((entry, index) => (
                                                                <li key={`item-${index}`} style={{ marginBottom: 10}}>
                                                                    <span style={{ color: entry.color, fontWeight: 'bold' }}>●</span>
                                                                    <span style={{ marginLeft: 8 }}>{entry.value}</span>
                                                                </li>
                                                            ))}
                                                            </ul>
                                                        )}
                                                    /> */}
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="column" alignItems="center">
                                                <Grid item sx={{display: "flex", textAlign: "left"}}>
                                                    <Typography variant="body1" sx={{ marginTop: "0.2rem", fontWeight: "bold" }}>{t("ConsumerPlanCalculation.pieExplanationTitle")}</Typography>
                                                </Grid>
                                                <Grid item sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                                                    <Typography>
                                                        <Box component="span" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>{`• ${t("ConsumerPlanCalculation.largerSection")}`}</Box> 
                                                        <Box component="span" sx={{ fontSize: "0.875rem"}}>{` = ${t("ConsumerPlanCalculation.largerSectionExplanation")}`}</Box>
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                                                    <Typography>
                                                        <Box component="span" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>{`• ${t("ConsumerPlanCalculation.eachWeight")}`}</Box> 
                                                        <Box component="span" sx={{ fontSize: "0.875rem"}}>{` ${t("ConsumerPlanCalculation.eachWeightExplanation")}`}</Box>
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                                                    <Typography>
                                                        <Box component="span" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>{`• ${t("ConsumerPlanCalculation.balance")}`}</Box> 
                                                        <Box component="span" sx={{ fontSize: "0.875rem"}}>{` ${t("ConsumerPlanCalculation.balanceExplanation")}`}</Box>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12} sm={0} md={0} sx={{display: { sm: "none"}}}>
                                <Divider sx={{marginTop: "1.5rem"}} />
                            </Grid>
                            {/*Need Action section - Moved and redesigned*/}
                            <Grid item xs={12} sm={12} md={6} sx={{ display: {xs: "flex", sm: "flex"}, justifyContent: "center", height: "calc(100vh - 4rem)", px: 3, borderRadius: 0.75, bgcolor: "white" }}>
                                <TopicSummary 
                                    loginUser={loginUser}
                                    staticSummary={true}
                                    username={props.location?.state?.username ? props.location?.state?.username : null}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </Box>
        </Box>
    )
}

import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import NavBar from "./layout/NavBar";
import Container from "./layout/Container";


import {
  HanldeProfile,
  CreateWellnessTopic,
  CreateWellnessAttribute,
  CreateWellnessChoice,
  ChangeWellnessTopic,
  ChangeWellnessAttribute,
  ChangeWellnessChoice,
  ClientsReport,
  AdvisorsReport,
  ManageSystemPrompts,
  ChatSessionsList,
  ChatSessionDetail,
  AdminClientsDashboards,
  ManageUsers,
  ChangeUserInfo,
} from "./pages/admin";

import {
  Activity,
  AMLForm,
  ContactLocation,
  IDInformation,
  PersonalInformation
} from "./pages/AML-Information";

import { 
  ConsumerPlan,
  ConsumerPlanDetail,
  ConsumerPlanCalculation,
  LlmChat,
  LlmQuestionsChat,
  ConsumerAdvisorMapping,
  SpeachToText,
  SpeechToTextStreaming,
  ConsumePlanChoose,
  LlmChoose,
  CompleteWellnessPlan,
  CombinedConsumerPlan
 } from "./retirement/consumer";

import {
  ClientSessionsList,
  ClientDashboard,
  ClientPlanForm,
  ClientManagement,
} from "./retirement/advisor";


import history from "./HistoryExport"

const AuthedRoutes = (mainProps) => {

// console.log(mainProps.navBarEnabled) //DEBUG
  return (
    <Router history={history}>
    {mainProps.navBarEnabled ? <NavBar {...mainProps} /> : "" }
      <Container>
        <Switch>
        
          {/* <Route exact path="/" component={Home} /> */}

          <Route
            exact
            strict
            path="/handle_profile"
            render={(props) =>
              React.createElement(HanldeProfile, { ...props, ...mainProps })}
          />

          <Route 
            exact 
            path="/onboarding/personalinformation" 
            render={(props) => 
            React.createElement(PersonalInformation, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/account/generalinfo" 
            render={(props) => 
            React.createElement(AMLForm, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/account/idinfo" 
            render={(props) => 
            React.createElement(IDInformation, { ...props, ...mainProps })} />
          <Route 
            exact 
            path="/account/contactinfo" 
            render={(props) => 
            React.createElement(ContactLocation, { ...props, ...mainProps })} />
          <Route 
            exact 
            path="/account/activityinfo" 
            render={(props) => 
            React.createElement(Activity, { ...props, ...mainProps })} />  
          <Route 
            exact 
            path="/admin/wellness_structure/new_topic" 
            render={(props) => 
            React.createElement(CreateWellnessTopic, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/new_attribute" 
            render={(props) => 
            React.createElement(CreateWellnessAttribute, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/new_answer" 
            render={(props) => 
            React.createElement(CreateWellnessChoice, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/change_topics" 
            render={(props) => 
            React.createElement(ChangeWellnessTopic, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/change_attributes" 
            render={(props) => 
            React.createElement(ChangeWellnessAttribute, { ...props, ...mainProps })} 
          />
           <Route 
            exact 
            path="/admin/wellness_structure/change_answers" 
            render={(props) => 
            React.createElement(ChangeWellnessChoice, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/reports_consumers" 
            render={(props) => 
            React.createElement(ClientsReport, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/reports_advisors" 
            render={(props) => 
            React.createElement(AdvisorsReport, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/grace_system_prompts" 
            render={(props) => 
            React.createElement(ManageSystemPrompts, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/grace_sessions" 
            render={(props) => 
            React.createElement(ChatSessionsList, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/grace-sessions/detail" 
            render={(props) => 
            React.createElement(ChatSessionDetail, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/clients_dashboard" 
            render={(props) => 
            React.createElement(AdminClientsDashboards, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/manage_users" 
            render={(props) => 
            React.createElement(ManageUsers, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/change_user_info" 
            render={(props) => 
            React.createElement(ChangeUserInfo, { ...props, ...mainProps })} 
          />


          {/* Consumers */}
          <Route 
            exact 
            path="/retirement_plan/topics" 
            render={(props) => 
            React.createElement(ConsumerPlan, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/retirement_plan/detail" 
            render={(props) => 
            React.createElement(ConsumerPlanDetail, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/retirement_plan/dashboard" 
            render={(props) => 
            React.createElement(ConsumerPlanCalculation, { ...props, ...mainProps })} 
          /> 
          <Route 
            exact 
            path="/consumer/ai-grace" 
            render={(props) => 
            React.createElement(LlmChat, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/retirement_plan/grace_form" 
            render={(props) => 
            React.createElement(LlmQuestionsChat, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/consumer/add_advisor" 
            render={(props) => 
            React.createElement(ConsumerAdvisorMapping, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/consumer/speech_to_text" 
            render={(props) => 
            React.createElement(SpeechToTextStreaming, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/consumer/questions_options" 
            render={(props) => 
            React.createElement(ConsumePlanChoose, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/consumer/llm_choose" 
            render={(props) => 
            React.createElement(LlmChoose, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/consumer/complete_wellness_plan" 
            render={(props) => 
            React.createElement(CompleteWellnessPlan, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/consumer/manual_plan" 
            render={(props) => 
            React.createElement(CombinedConsumerPlan, { ...props, ...mainProps })} 
          />

          {/* Advisors */}
          <Route 
            exact 
            path="/advisors/clients_sessions" 
            render={(props) => 
            React.createElement(ClientSessionsList, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/advisors/client_dashboard"
            render={(props) => 
            React.createElement(ClientDashboard, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/advisors/client_plan"
            render={(props) => 
            React.createElement(ClientPlanForm, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/advisors/new_client"
            render={(props) => 
            React.createElement(ClientManagement, { ...props, ...mainProps })} 
          />
          
          {/* Add a catch-all route at the end to redirect to root */}
          <Route path="*">
            <Redirect to="/" />
          </Route>
         
        </Switch>
      </Container>
    </Router>
  );
}

export default AuthedRoutes;

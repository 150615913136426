import React from 'react';
import {Box, Grid, TextField, Typography, Dialog, List, ListItem, 
    Avatar, ListItemButton} from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getAllWellnessTopic} from '../../utilityFunctions/WellnessUtil';
import Colors from "../../components/ui/Colors";
import { loaderAnimation } from '../../Api';
import { useTranslation } from 'react-i18next';
import FaceIcon from '@mui/icons-material/Face';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let selectedTopic;

export default function ConsumerPlan(props) {
    const { loginUser, isEmbedded, onTopicSelect } = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const {t} = useTranslation();

    const [wellnessTopics, setWellnessTopics] = React.useState(null);

    React.useEffect(() => {
        console.log(props.location?.state?.username);
        selectedTopic=null;
        renderTopics();
    }, []);

    const initialValues = async () => {
        setLoading(true);
        const response = await getAllWellnessTopic(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setWellnessTopics(response);
        return response;
    };

    const [topicsList, setTopicsList] = React.useState("");

    const renderTopics = async () => {

        const topics = await initialValues();

        if(!topics){
            return;
        }

        // Sort the array by renderingOrder, with nulls at the end
        topics.sort((a, b) => {
            if (a.renderingOrder === null) return 1; // a goes to the end
            if (b.renderingOrder === null) return -1; // b goes to the end
            return a.renderingOrder - b.renderingOrder; // Normal comparison
        });
        

        setTopicsList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {topics.map((topic) => {
                    return (
                        <ListItem key={topic.id} disablePadding sx={{marginBottom: "2rem"}}>
                            <ListItemButton onClick={() => openActionBox(topic)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", textAlign: "left"}}>
                                        <Typography variant="body1" sx={{color: "black"}}>{topic.name}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        )

    };

    const[actionBox, setActionBox] = React.useState(false);

    const openActionBox = (topic) => {
        selectedTopic = topic;
        if (isEmbedded) {
            onTopicSelect(topic);
        } else {
            goToManualForm();
        }
    };

    const closeActionBox = () => {
        setActionBox(false);
    };

    const goToLlmForm = () => {
        console.log(selectedTopic);
        history.push({
            pathname: "/retirement_plan/grace_form",
            state: {
                topic: selectedTopic,
                reference: "form_system_initial",
            }
        });
    };

    const goToManualForm = () => {
        history.push({
            pathname: "/retirement_plan/detail",
            state: {
                topic: selectedTopic,
                username: props.location?.state?.username ? props.location?.state?.username : null
            }
        });
    };

    return (
        <Box sx={{
            display: "flex", 
            justifyContent: isEmbedded ? "center" : "start", 
            width: "100%", 
            height: "100%",
            // Adjust padding based on whether it's embedded
            padding: isEmbedded ? "1rem" : 0
        }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: isEmbedded ? "center" : "start",
                    alignItems: isEmbedded ? "center" : "start",
                    width: "95%",
                    height: isEmbedded ? "100%" : "90%",
                    textAlign: "left",
                    marginTop: {xs: "1.3rem", sm: 0},
                    overflowY: "scroll"
                }}
            >
                {loading ? loaderAnimation:
                    <>
                        <Grid container direction="row" sx={{height: "100%"}}>
                            <Grid item xs={12} sx={{height: "100%"}}>
                                {topicsList}
                            </Grid>
                        </Grid>

                        <Dialog open={actionBox} onClose={closeActionBox}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: {xs: "80%", sm: "40%", md: "30%"},
                                    padding: "1.5rem", // Add padding to the dialog
                                    borderRadius: "16px", // Softer corners
                                },
                            },
                        }}
                        >
                            <List sx={{width: "100%",}}>
                                <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                    <ListItemButton  onClick={goToLlmForm}>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <Avatar sx={{ backgroundColor: Colors.primary, width: "3rem", height: "3rem" }}>
                                                    <FaceIcon sx={{ fontSize: "1.5rem", color: "white" }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <Typography variant="h6" sx={{ml:{xs: "0.8rem", sm: "1rem"}}}>{t("HandleProfile.grace")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                    <ListItemButton  onClick={goToManualForm}>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <Avatar sx={{ backgroundColor: Colors.primary, width: "3rem", height: "3rem" }}>
                                                    <FormatListNumberedIcon sx={{ fontSize: "1.5remrem", color: "white" }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <Typography variant="h6" sx={{ml:{xs: "0.8rem", sm: "1rem"}}}>{t("ConsumerPlan.manualForm")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Dialog>
                    </>
                }
            </Box>
        </Box>
    )
}
